import { Subject } from 'rxjs';
import CryptoJS from 'crypto-js';
import { ActiveSession } from '../models/activeSession';

export class LocalManager {
    public static sessionChangeEvent: Subject<void> = new Subject<void>();
    private static readonly ACTIVE_SESSION_KEY = 'activeSession';
    private static readonly ENCRYPTION_KEY = 'your-secure-encryption-key'; // Replace with a secure key

    static getActiveSession(): ActiveSession {
        const encryptedActiveSession = localStorage.getItem(LocalManager.ACTIVE_SESSION_KEY);
        if (!encryptedActiveSession) {
            return {};
        }

        try {
            const decryptedActiveSession = CryptoJS.AES.decrypt(
                encryptedActiveSession,
                LocalManager.ENCRYPTION_KEY,
            ).toString(CryptoJS.enc.Utf8);

            if (decryptedActiveSession) {
                return JSON.parse(decryptedActiveSession) as ActiveSession;
            }
        } catch (error) {
            console.error('Error decrypting active session:', error);
        }

        return {};
    }

    static setActiveSession(activeSession: ActiveSession = {}): void {
        this.sessionChangeEvent.next();
        const activeSessionJson = JSON.stringify(activeSession);
        const encryptedActiveSession = CryptoJS.AES.encrypt(
            activeSessionJson,
            LocalManager.ENCRYPTION_KEY,
        ).toString();
        localStorage.setItem(LocalManager.ACTIVE_SESSION_KEY, encryptedActiveSession);
    }

    static clearActiveSession(): void {
        localStorage.removeItem(LocalManager.ACTIVE_SESSION_KEY);
        this.sessionChangeEvent.next();
    }
}
