import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import AuthHelper from '../utils/authHelper';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ApiClient {
    private http = inject(HttpClient);

    private accessToken: string = '';
    private readonly baseUrl: string = environment.apiUrl;

    constructor() {
        this.initializeAccessToken();
    }

    public post<T>(path: string, data: unknown): Observable<T> {
        const url = this.baseUrl + path;
        return this.http.post<T>(url, data, this.getRequestOptions());
    }

    public put<T>(path: string, data: unknown): Observable<T> {
        const url = this.baseUrl + path;
        return this.http.put<T>(url, data, this.getRequestOptions());
    }

    public get<T>(path: string): Observable<T> {
        const url = this.baseUrl + path;
        return this.http.get<T>(url, this.getRequestOptions()).pipe(map((result) => result));
    }

    public delete<T>(path: string): Observable<T> {
        const url = this.baseUrl + path;
        return this.http.delete<T>(url, this.getRequestOptions());
    }

    private initializeAccessToken(): void {
        this.accessToken = AuthHelper.getSavedAccessToken() ?? '';
    }

    private getRequestOptions(): { headers: HttpHeaders } {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            ...(this.accessToken && { Authorization: `Bearer ${this.accessToken}` }),
        });

        return { headers };
    }
}
