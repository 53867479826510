import { Injectable } from '@angular/core';
import { LocalManager } from './localManager';

@Injectable()
export default class AuthHelper {
    static getSavedAccessToken() {
        const activeSession = LocalManager.getActiveSession();
        if (activeSession) {
            return activeSession.accessToken;
        } else {
            return activeSession;
        }
    }
}
